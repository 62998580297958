<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs defaultValue="0"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane key="0"
                    :tab="stageName"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <div @click="visible=true"
               class="progress">
            <img src="@/assets/epc/progress.jpg"
                 alt="">
            <a>流程查看</a>
          </div>
          <div class="button"
               @click="exportForm"
               v-if="isView">
            <img :src="exportImage" />
            <span>导出文档</span>
          </div>
          <a-dropdown>
            <div class="button">
              <img :src="downloadImage" />
              <span>下载模板</span>
            </div>
            <div slot="overlay"
                 class="content">
              <div v-for="(item) in templateList"
                   :key="item.id"><span @click="download(item)">《{{item.name}}》</span></div>
            </div>
          </a-dropdown>
        </div>
      </template>
    </Pane>

    <div class="container">
      <a-form :form="form"
              @submit="handleSubmit"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }">
        <a-row>
          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="当前项目"
                         class="default-highlight"
                         :label-col="{ span: 8 }"
                         :wrapper-col="{ span: 12 }">
              <a-input :disabled="true"
                       :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="工程名称"
                         class="default-highlight">
              <a-input :disabled="true"
                       :placeholder="projectName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="检查内容">
              <a-input v-decorator="[
                'content',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                    },
                  ],
                },
              ]"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="问题及整改要求">
              <a-input v-decorator="[
                'rectificationRequirement',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                    },
                  ],
                },
              ]"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item>
              <span slot="label">附件</span>
              <div class="link-list"
                   v-decorator="['file']">
                <a-checkbox-group :value="selectedFileList"
                                  @change="onSelectFile"
                                  v-if="fileList.length !== 0">
                  <div class="link"
                       v-for="item in fileList"
                       :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank"
                         download
                         :href="item.completePath">{{
                      item.name
                    }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
                <div style="color:#ccc;"
                     v-else-if="fileList.length === 0 && id">
                  无
                </div>
                <div style="color:#ccc;line-height:1.5;"
                     v-else>
                  《资料检查记录表》
                </div>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>
                  <div class="control"
                       @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button html-type="submit"
                      type="primary"
                      v-if="!isView">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </div>

    <a-modal :visible="visible"
             title="流程图"
             cancelText="关闭"
             :footer="null"
             @cancel="visible=false">

      <img src="@/assets/epc/approvalFlow/4-5-ziliao.png"
           style="width:100%">
    </a-modal>
  </div>
</template>

<script>
import FileUpload from "@/components/file-upload";
import { saveAs } from "file-saver";

import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  fetchDetail as fetchFileDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/construction";

import { mapGetters } from "vuex";

export default {
  name: "construData",
  components: {
    FileUpload,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),
      stage: "epc_construction_document_review",
      isView: false, //控制导出按钮是否显示
      processDisable: false,
      visible: false,

      form: this.$form.createForm(this),
      pid: "",
      id: "",
      hid: "",
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
    templateList() {
      let list = [
        {
          value: "construction_document_review_save_dir",
        },
        {
          value: "construction_document_review_transfer",
        },
        {
          value: "construction_document_review_record",
        },
      ];
      list.forEach((item, index) => {
        item.id = index;
        item.name = this.findSingleDictName("epc_file_export_type", item.value);
      });
      return list;
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail(); // 从文件列表进入时，获取详情
    }
    this.getName(); // 获取工程名称，设计编号
  },
  methods: {
    getDetail() {
      fetchFileDetail({ id: this.id }).then((res) => {
        if (!res.id) return;

        this.form.setFieldsValue({
          content: res.content,
          rectificationRequirement: res.rectificationRequirement,
        });

        if (res.attachmentList) {
          this.form.setFieldsValue({
            file: res.attachmentList,
          });
          this.fileList = res.attachmentList;
        }
      });
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;

          if (!this.id) {
            console.log("新增");
            console.log(this.fileList);
            console.log(values);

            add({
              ...values,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          } else {
            edit({
              ...values,
              id: this.id,
              historyId: this.hid,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },

    exportForm() {
      // 只导出工程资料检查记录表
      let fileExportType = "construction_document_review_record";
      exportFile({ id: this.id, fileExportType })
        .then((blob) => {
          saveAs(blob, `${this.projectName}_工程资料检查记录表.docx`);
        })
        .catch();
    },
    download(item) {
      downloadTemplate(item.value)
        .then((blob) => {
          saveAs(blob, `${item.name}模版.docx`);
        })
        .catch();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background-color: #fff;
  border: 1px solid #ccc;
  div {
    padding: 5px;
    &:hover {
      background-color: #1890ff;
      color: #fff;
      cursor: pointer;
    }
    span {
      display: block;
      text-align: center;
    }
  }
  div:last-child {
    margin-bottom: 0;
  }
}

.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
